import CssBaseline from '@mui/material/CssBaseline';
import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Feed } from './components/Feed';
import { Footer, Header } from './components/SharedComponents';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <div style={{ position: 'relative' }} >
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Container maxWidth="sm" sx={{ paddingTop: '100px' }}>
          <Feed />
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
