import { Fragment, useState } from 'react'
import { Grid } from '@mui/material';
import { EventCard, EventCardProps } from '../EventCard';
import { getEvents } from '../../services/api'

export const Feed = () => {
  const [events] = useState(getEvents())
    
  return (
    <Fragment>
        <Grid container  direction="column" rowSpacing={2}>
          {events.map(
            (event, index) => <EventCard key={`${event.title}-${index}`} post={event as EventCardProps['post']} />
          )}
        </Grid>
    </Fragment>
  );
}