import { Fragment } from 'react';
import { Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const Header = () => {
  return (
    <Fragment>
      <Toolbar 
        sx={
          { 
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: 4,
            position: 'fixed',
            width: '100%',
            zIndex: 10,
            backgroundColor: '#121212',
            display: 'flex',
            justifyContent: 'space-around'
          }
        }
      >
        <Button sx={{ position: 'fixed', left: 24, display: { xs: 'none', sm: 'block' }}} size="small">
          Fale com a gente!
        </Button>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
          className="notranslate"
        >
          Near Events
        </Typography>
        <IconButton>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </Fragment>
  );
}