type TEventMocks = {
    [key: string]: unknown
}

export const eventMocks: TEventMocks[] = [
    {
      title: 'Forró do Gerso',
      date: 'Nov 12',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
      image: 'https://source.unsplash.com/random?wallpapers',
      imageLabel: 'Image Text',
    },
    {
      title: 'Psy 2005',
      date: 'Nov 11',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
      image: 'https://source.unsplash.com/random?wallpapers',
      imageLabel: 'Image Text',
    },
  ];

export const getEvents = (filter?: Record<string, unknown>) => {
    return eventMocks.filter((event) => {
        if (!filter) return true

        const [[key, value]] = Object.entries(filter)

        return event[key] === value
    })
}